@import "../../../style/library";
.aiHeader {
  display: flex;
  justify-content: end;

  p {
    font-size: 16px;
    line-height: 22px; }

  .summ {
    height: 30px;
    width: 115px;
    fill: #fff;
    color: #fff;
    align-items: center;
    border: none;
    outline: none;
    justify-content: center;
    border-radius: 5px; }

  .textback {
    height: 30px;
    padding: 17px 8px;
    fill: #fff;
    color: #fff;
    align-items: center;
    border: none;
    outline: none;
    justify-content: center;
    border-radius: 2px; } }
