.mainCont {
  width: 100%;
  height: 160px;
  top: -175px;

  .sliderCont {
    height: 100%;
    position: relative;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .14);

    h3 {
      color: #707070;
      font-size: 18px;
      font-weight: bold; }

    .actions {
      display: grid;
      grid-template: 1fr / 250px 1fr;
      grid-gap: 30px;
      @media(max-width: 1200px) {
        grid-gap: 20px; }

      .desc {
        height: 100px;
        overflow: auto;
        align-self: center;
        margin: 0;
        line-height: 20px;
        color: #707070;
        overflow: auto; }

      .select {

        .field {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #585858;
          cursor: pointer;
          color: #fff;
          padding: 6px;
          border-radius: 5px;

          p {
            margin: 0;
            font-size: 14px;
            font-weight: bold; } }

        ul {
          width: 250px;
          list-style: none;
          background: #fff;
          border-radius: 5px;
          padding: 8px;
          box-shadow: 0 1px 2px 0 rgba(140, 119, 93, 0.5);

          li {
            cursor: pointer;
            color: #707070;
            font-size: 18px;
            font-weight: bold;

            &:hover {
              color: #404040;
              background: rgba(0, 0, 0, .03); } } } } }

    .switch {
      display: flex;
      align-items: center;

      p {
        font-weight: 400;
        font-size: 14px; } }

    .slider {
      position: absolute;
      right: 10%;
      top: 15px;
      width: 50%; }

    .warning {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      height: 100%;
      text-align: center;
      margin: auto;
      line-height: 20px;
      color: #707070; } } }

