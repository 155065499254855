@import "../../../../style/library";


.termModal {
  color: #585858;
  width: 70vw;
  background: #fff;
  padding: 20px;
  border-radius: 14px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80vh;

  .careful {
    color: #f50057; }

  .info {
    padding: 0;
    color: #585858;
    height: 16px;
    margin-bottom: 6px; }

  h2 {
    font-size: 32px;
    color: #707070;
    margin: 0 0 20px 0;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #ccc; }

  .partTitle {
    font-size: 18px;
    color: #707070;
    font-weight: bold; } }

.alignItem {
  justify-content: center; }

.uploadBtn {
  width: 300px;
  position: relative;
  height: 100px; }

.rightBox {
  margin-top: 5px;
  background-color: #FDE992;
  border: 15px none gray;
  border-radius: 15px;
  position: relative;
  right: 10; }
