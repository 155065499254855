@import "../../../style/library";
.header {
  position: relative;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 5px 0;

  @media (min-width: 1200px) {
    max-width: 1500px;
    padding: 0 10px 0;
    margin: 0 auto; }

  .firstPart {
    width: 100%;
    .burger {
      width: 45px;
      object-fit: contain;
      cursor: pointer;
      margin-right: 26px; }

    .logo {
      height: 30px;
      object-fit: contain; } }

  .secondPart {
    padding: 0;
    display: grid;
    grid-template: 1fr / auto 1fr 1fr;
    justify-items: end;
    z-index: 2;

    .notifyIcon {

      height: 40px;
      width: 40px;
      display: flex;
      border-radius: 50%;
      padding: 9px;
      cursor: pointer;
      img {
        height: 100%; }
      .notifyCount {
        position: absolute;
        top: -4px;
        right: -9px;
        height: 19px;
        width: 19px;
        padding: 2px;
        border-radius: 50%;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center; } }
    .avatar {
      height: 40px;
      width: 40px;
      cursor: pointer; } }

  .select {
    position: absolute;
    top: 94px;
    right: 45px;
    width: 320px;
    border: none;
    filter: $middle-drop-shadow;
    padding: 0 12px 30px;
    z-index: 1;
    transition: .2s ease-in-out;
    .options {
      padding: 11px 0;
      text-decoration: none;
      border-bottom: 1px solid;
      img {
        height: 20px;
        width: 21px;
        margin: 0 21px 0 5px; } }
    .triangle {
      position: absolute;
      right: 0;
      top: -13px; } } }

