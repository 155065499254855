@import "../../../style/library";
.headPart {
  height: 40px;

  .text {
     overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 2 /* number of lines to show */ {
            line-clamp: 2; }
     -webkit-box-orient: vertical;
     word-break: break-all;
     line-height: 1em;
     font-size: 15px; }

  .downloadMenu {
    position: absolute;
    padding: 5px 5px 5px 5px;
    border-color: #b9b9ba;
    border-style: solid;
    border-width: thin;
    background-color: #FFFFFF;
    z-index: 5;
    margin: 211px 0 0 90px;

    .downloadMenuUl {
      margin: 0 0 0 0;
      padding: 0 0 0 0;
      list-style-type: none;
      cursor: pointer; } }

  .hidOnSmallScreen {
    @media(max-width: 1295px) {
      display: none !important; } }

  .others {
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .hiddenOthers {
    min-width: 150px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #fff;

    p:hover {
      color: rgba(0, 0, 0, 0.47); } }

  p {
    font-size: 16px;
    line-height: 22px;
    cursor: pointer; }

  img {
    cursor: pointer; }

  .hr {
    height: 100%;
    border-left: 1px solid; }

  .withIcon {
    position: relative;
    cursor: pointer;

    &:hover {
      .icon {
        transform: scale(1.2); } }

    .icon {
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      transition: .02s ease-in;
      right: -6px;
      fill: #fff;
      border-radius: 2px; } }

  .neu {
    fill: #fff;
    color: #fff;
    align-items: center;
    border: none;
    outline: none;
    justify-content: center;
    border-radius: 5px;

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 14px; } }

  .actualBtn {
    height: 30px;
    max-width: 150px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 4px;
    color: white;
    margin: 0;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12);

    svg {
      width: 18px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      height: 18px;
      object-fit: contain; }

    p {
      width: 115px;
      font-size: 14px;
      font-weight: bold;
      margin: 0; } }

  .autoWidth {
    max-width: none !important;

    p {
      width: auto !important; } } }


@mixin modalContent() {

  .buttonBox {
    height: 100px !important;
    width: 250px;
    position: absolute;
    bottom: 0;
    margin-bottom: -110px;
    overflow: scroll;

    .tagButtons {
      background-color: rgb(231, 239, 252);
      margin-right: 5px;
      margin-top: 5px;
      padding: 8px 15px;
      font-size: 16px;
      color: rgb(65, 92, 152); } }

  &.open {
    display: block; }

  .head {
    border-radius: 8px 8px 0 0;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      margin: 0;
      color: #FFFFFF; } }

  .descText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: -0.027em; }
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ccc;

    li {
      width: 33.3333%;
      text-align: center;
      font-weight: 600;

      a {
        color: #202020;
        border-radius: 0 !important; } } } }

.error {
  font-size: 15px;
  // margin-top: 40px
  // color: red
  width: 400px;
  margin: 0 auto; }

.folderLimit {
  font-size: 15px;
  width: 300px;
  margin: 0 auto;
  margin-left: 20px;
  height: 10px; }


.modalMainContForVersion {
  width: 700px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  outline: none;

  @include modalContent(); }


.modalMainCont {
  width: 700px;
  height: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  outline: none;

  @include modalContent(); }

.folderBar {
  display: flex;
  flex-direction: row;

  .firstPlus {
    position: absolute;
    margin-left: 250px;
    margin-top: 33px;
    top : 0; }

  .secondPlus {
    position: relative;
    margin-left: 50px; } }


.analyseProgressbar {
  width: 250px;
  cursor: pointer;

  .title, .errorTitle {
    font-weight: bold;
    display: block;
    font-size: 14px;
    margin-bottom: 8px; }

  .title {
    color: #43AB1B; }

  .errorTitle {
    color: #AB431B; }

  .bar {
    width: 90%;
    height: 6px;
    position: relative; }

  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #cccccc; }

  .front, .errorFront, .countdownFront {
    position: absolute;
    height: 100%; }

  .front {
    background-color: #43AB1B; }

  .countdownFront {
    background-color: #3f51b5; }

  .errorFront {
    background-color: #AB431B; } }
