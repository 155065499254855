.mainCont {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 22px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  padding: 50px 40px 0;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px 0; }

  .card {
    width: 350px;
    border: 1px solid;
    border-radius: 14px;
    justify-self: center;

    .title {
      font-weight: 800;
      font-size: 18px;
      line-height: 25px; }

    .row {
      .circle {
        width: 6px;
        height: 6px;
        border-radius: 50%; }

      a {
        font-size: 14px;
        line-height: 23px;
        font-weight: 600; } } } }
