@import "../../../../style/library";
.mainCont {

  min-height: 845px;
  max-height: 100vh;
  width: 420px;
  border-radius: 10px;
  display: grid;
  grid-template: 160px calc(100% - 160px) / 1fr;
  grid-gap: 12px;
  font-weight: bold;
  font-size: 14px;
  color: gray;

  @media(max-width: 1200px) {
    width: 325px;
    min-height: unset;
    height: 99%;
    transform: translateY(141px);

    @media (max-width: 1025px) {
      transform: translateY(225px); } }

  .filterbox {
    position: absolute;
    margin-top: 30px; }

  .header {
    height: 100%;
    border-radius: 10px;

    .tabHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 10px 0;
      border-bottom: 1px solid;
      margin: 0 0 16px;

      p {
        font-weight: bold;
        font-size: 18px;
        margin: 0; }

      .number {
        width: 47px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        line-height: 23px;
        font-weight: bold;
        border-radius: 5px; } }

    .hixClix {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: #fff;
      grid-gap: 10px;

      button {
        border-radius: 10px;
        background: #fff;
        color: #707070;
        padding: 3px 5px;
        filter: $soft-drop-shadow;
        transition: .05s ease-in; }

      .activeHixClix {
        background: #707070;
        color: #fff; } }

    .aktiv {
      p {
        opacity: 0.38;
        font-size: 18px;
        font-weight: bold;
        margin: 0; }

      .border {
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .1s ease-in;
        border-radius: 50%;
        border: 1px solid;

        div {
          height: 15px;
          width: 15px;
          border-radius: 50%; } } } }

  .rows {
    // height: 100%
    max-height: 675px;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 10px;

    .row {
      width: 100%;

      .title {
        font-size: 16px;
        font-weight: bold;
        padding-left: 53px;
        cursor: pointer; }

      .number {
        position: absolute;
        width: 40px;
        height: 20px;
        display: flex;
        align-items: center;
        border-radius: 5px;

        div {
          width: 7px;
          height: 100%;
          border-radius: 5px 0 0 5px; }

        p {
          font-size: 16px;
          font-weight: bold;
          margin: 0 auto; } } }

    .opened {
      border-bottom: none; }

    .firstRow {
      border-top: none; } } }
